const dev = {
  STRIPE_KEY: "pk_test_51Gr6GwFSDVuf18A2gRuY7VEBsLzkS0lt5lO2KEYSfM9yQOpVDFLLo47QpHPk1NVm8sG8lA8YSJ9LDCAYS6O6CGL300dZV9ygft",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-dev-attachmentsbucket-57l3nmhye8dv"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.zhaous.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_YJaF0Pc41",
    APP_CLIENT_ID: "7oka46s7k0ge8uvhersulg86ks",
    IDENTITY_POOL_ID: "us-east-1:6b630c37-2655-45b4-9ec2-aeccad59793c"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_51Gr6GwFSDVuf18A2gRuY7VEBsLzkS0lt5lO2KEYSfM9yQOpVDFLLo47QpHPk1NVm8sG8lA8YSJ9LDCAYS6O6CGL300dZV9ygft",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-prod-attachmentsbucket-1juztnlal7tc3"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.zhaous.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_ErKGhJmwd",
    APP_CLIENT_ID: "5hccf38dlnf0jnq47i6rn91ovo",
    IDENTITY_POOL_ID: "us-east-1:f3ac7168-96b1-422a-be45-e13acf249f96"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
